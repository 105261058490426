.restaurantsContainer {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.activeCuisine {
  background-color: #002868;
  color: #eee;
}

.restaurant-sideBar-buttons {
  display: flex;
  flex-direction: column;
  gap: 1em;
}



