.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* //////////////// GLOBAL /////////////// */
.display-none {
  display: none;
}

/* //////////////// Header, header logo, and navigation //////////////// */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.headerMenu {
  padding: 10px;
  display: flex;
  justify-content: center;
  background-color: #002868;
}

.headerMenu ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 15vw;
}

.headerMenu a {
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: stretch;
  font-weight: bold;
  color: #ffffff;
  font-size: 1.5rem;
}

.headerMenu a:hover {
  color: #bf0a30;
  text-decoration: underline;
  text-decoration-color: #ffd700;
}

.headerMenu a:active {
  text-decoration: underline;
  text-decoration-color: #ffd700;
}

.NomNoms-logo {
  height: 25vh;
}

/* //////////////// Home Page  //////////////// */

.noms-home-container {
  /* background-color: #002868; */
  color: #ffffff;
  width: 100%;
}

.noms-image-container {
  display: flex;
  width: 100%;
  height: 30vmin;
  background-image: url("./images/littleton_foothills.jpg");
  background-repeat: no-repeat;
  background-size:cover;
  /* background-position:inherit; */
}

.nom-container-one {
  background-color: #002868;
  color: #ffffff;
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
}

.noms-home-intro-content {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 90%;
  font-size: large;
  font-weight: 500;
}

.noms-container-two {
  height: auto;
  background-color: #ffffff;
  color: #002868;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.restaurant-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-self: center;
  text-align: center;
  background-color: #ffffff;
  justify-content: center;
  align-self: center;
  text-align: center;
  min-height: 45vh;
  /* gap: 5rem; */
}

.restaurant-content-title {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  background-color: #bf0a30;
  color: #ffffff;
}

.home-restaurant-items {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s ease-in-out;
}

.home-restaurant-items a {
  display: flex;
  flex-direction: column;
  color: #002868;
  font-weight: 500;
  text-decoration: none;
}

.home-restaurant-items:hover {
  background-color: rgba(255, 0, 0, 0.5);
  transition: 1s ease-in-out;
  border-radius: 10px;
}

.restaurant-content img {
  align-self: center;
  border-radius: 5px;
}

.noms-container-three {
  background-color: #ffffff;
  color: #002868;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.recipe-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-self: center;
  text-align: center;
  background-color: #ffffff;
  justify-content: center;
  align-self: center;
  text-align: center;
}

.recipe-content-title {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  background-color: #bf0a30;
  color: #ffffff;
}

.recipe-content img {
  align-self: center;
  border-radius: 5px;
}

.home-recipe-items {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.home-recipe-items a {
  display: flex;
  flex-direction: column;
  color: #002868;
  font-weight: 500;
  transition: 1s ease-in-out;
}

.home-recipe-items a:hover {
  background-color: #0028683b;
  height: auto;
  transition: 1s ease-in-out;
  border-radius: 10px;
}

.reicpe-content img {
  align-self: center;
}

/* //////////////// Restaurant and restaraunt side bar //////////////// */

.restaurant-restaurantContent {
  display: flex;
}

.restaurant-sideBar {
  justify-content: center;
  text-align: center;
  width: 20%;
  border: 2px;
  border-color: #002868;
}

.restaurant-sideBar li {
  list-style-type: none;
  padding: 2px;
}

.restaurant-sideBar-buttons {
  border: solid 3px transparent;
  border-color: #002868;
  background-color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  color: #002868;
  border-radius: 10px;
  padding: 5px;
  border-width: 5px;
  width: 200px;
  transition-duration: 0.4s;
}

.restaurant-sideBar-buttons:hover {
  border-color: #bf0a30;
  color: #bf0a30;
  text-decoration: underline;
  text-decoration-color: #ffd700;
}

.restaurant-sideBar-buttons:active {
  border-color: #bf0a30;
  color: #bf0a30;
  text-decoration: underline;
  text-decoration-color: #ffd700;
}

.restaurant-page-content {
  text-align: left;
  width: 70%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.restaurant-space-left {
  width: 10%;
}

.restaurant-space-right {
  width: 10%;
}

.restaurant-titles-items a {
  display: flex;
  flex-direction: row;
  width: 100%;
  transition: 1s ease-in-out;
  text-decoration: none;
  color: #002868;
  font-weight: 500;
}

.restaurant-titles-items a:hover {
  background-color: rgba(255, 0, 0, 0.5);
  transition: 1s ease-in-out;
  border-radius: 10px;
}

/* .rest-links {
  display: flex;
  width: 100%;
  text-decoration: none;
}

.rest-links:hover {
  background-color: rgba(255, 0, 0, 0.5);
  transition: 1s ease-in-out;
  border-radius: 10px;
} */

.rest-img {
  order: 1;
  width: 25%;
  justify-content: center;
  align-self: center;
  padding: 10px;
}

.rest-img img {
  border-radius: 10px;
  height: 150px;
  width: 250px;
}

.restaurant-image img {
  height: 400px;
  width: 650px;
  border-radius: 10px;
}

.rest-review-content {
  text-align: left;
  width: 60%;
}

.rest-stuff {
  order: 2;
  width: 75%;
  padding: 10px;
}

.rest-line-divider {
  border-top: 5px solid #bf0a30;
  border-radius: 5px;
}

.noFoodImages {
  display: none;
}

.restaurant-food-image {
  display: flex;
  gap: 10px;
  padding: 10px;
}

.restaurant-food-image img {
  border-radius: 10px;
  height: 300px;
  width: 550px;
}

/* .allFoodImages-clicked {
  height: 400px;
  width: 650px;
} */

/* //////////////// Recipe and recipe side bar //////////////// */
.recipe-page-container {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 45vh;
  /* gap: 5rem; */
}

.recipe-page-sideBar {
  justify-content: center;
  text-align: center;
  width: 20%;
  border: 2px;
  border-color: #002868;
  list-style-type: none;
}

.recipe-disclaimer {
  background-color: #bf0a30;
  color: #fff;
  text-align: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.recipe-page-content {
  text-align: left;
  width: 70%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.recipe-page-content a {
  text-decoration: none;
}

/* .recipe-space-left {
  width: 10%;
}

.recipe-space-right {
  width: 10%;
} */

.recipe-page-sideBar li {
  list-style-type: none;
  padding: 2px;
}

.recipe-sideBar-buttons {
  border: solid 3px transparent;
  border-color: #002868;
  background-color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  color: #002868;
  border-radius: 10px;
  padding: 5px;
  border-width: 5px;
  width: 200px;
  transition-duration: 0.4s;
}

.recipe-sideBar-buttons:hover {
  border-color: #bf0a30;
  color: #bf0a30;
  text-decoration: underline;
  text-decoration-color: #ffd700;
}

.recipe-sideBar-buttons:active {
  border-color: #bf0a30;
  color: #bf0a30;
  text-decoration: underline;
  text-decoration-color: #ffd700;
}

.recipe-page-titles-items a {
  display: flex;
  flex-direction: row;
  width: 100%;
  transition: 1s ease-in-out;
  color: #002868;
  font-weight: 500;
}

.recipe-page-titles-items {
  display: flex;
  flex-direction: row;
  width: 100%;
  transition: 1s ease-in-out;
}

.recipe-page-titles-items:hover {
  background-color: rgba(255, 0, 0, 0.5);
  transition: 1s ease-in-out;
  border-radius: 10px;
}

.recipe-page-img {
  order: 1;
  width: 25%;
  justify-content: center;
  align-self: center;
  padding: 10px;
}

.recipe-page-img img {
  border-radius: 10px;
  height: 150px;
  width: 250px;
}

.recipe-main-food-image img {
  height: 400px;
  width: 650px;
  border-radius: 10px;
}

.recipe-stuff {
  order: 2;
  width: 75%;
  padding: 10px;
}

.recipe-line-divider {
  border-top: 5px solid #bf0a30;
  border-radius: 5px;
}

.recipe-links {
  display: flex;
  width: 100%;
  text-decoration: none;
}

.recipe-review-content {
  width: 60%;
  text-align: left;
}

.recipe-ingredients-image img {
  height: 400px;
  width: 650px;
  border-radius: 10px;
}

.recipe-ingredients-image-none img {
  display: none;
}

.recipe-instructions-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.recipe-instructions-images img {
  height: 300px;
  width: 550px;
  border-radius: 10px;
}

/* //////////////// About Page //////////////// */
.aboutPage {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.aboutSectionOne {
  display: flex;
  order: 1;
  width: 40%;
  justify-content: center;
  align-self: center;
  text-align: center;
  color: #002868;
  font-weight: 500;
  padding: 15px;
}

.aboutSectionTwo {
  display: flex;
  order: 2;
  width: 40%;
  justify-content: center;
  align-self: center;
  text-align: center;
  padding: 15px;
}

.chefMert {
  height: 50vh;
  border-radius: 15px;
}

/* //////////////// Contact Page //////////////// */

/* //////////////// Map Page //////////////// */
.map-container {
  height: 400px;
  width: 80%;
  text-align: center;
  margin: auto;
  border-radius: 25px;
  border: 3px solid #002868;
}

.map-heading {
  color: #002868;
}

.map-stuff {
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 5px;
}

.map-null {
  display: none;
}

.map-stuff-blurb {
  background-color: #002868;
  color: #ffffff;
  padding: 6px 12px;
  border-radius: 5px;
}

.map-clicked-data {
  color: #002868;
  padding: 6px 12px;
  transition: 1s ease-in-out;
}

.map-clicked-data img {
  border-radius: 5px;
}

.map-clicked-data a {
  color: #002868;
  text-decoration: none;
}

.map-clicked-data:hover {
  background-color: rgba(255, 0, 0, 0.5);
  transition: 1s ease-in-out;
  border-radius: 10px;
}

.map-sidebar {
  background-color: #bf0a30;
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: relative;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 5px;
}

.customMarker {
  width: 15px;
  height: 15px;
  background-color: #bf0a30;
  border: 2px solid #002868;
  border-radius: 50%; /* Makes it circular */
  transform: translate(-50%, -50%); /* Center the marker */
}

.customMarker:hover {
  border: 2px solid #ffd700;
}

/* //////////////// Footer //////////////// */
.footer {
  display: flex;
  flex-direction: row;
  background-color: #002868;
  padding: 15px;
}

.footerMenu {
  display: flex;
  order: 1;
  width: 50%;
  justify-content: center;
  text-align: center;
  align-self: center;
  text-decoration: none;
}

.footerMenu ul {
  list-style-type: none;
  justify-content: center;
  text-align: center;
  align-self: center;
  text-decoration: none;
  padding-inline-start: 0px;
}

.footerMenu li {
  text-decoration: none;
  font-size: large;
  font-weight: bold;
}

.footerMenu li:visited {
  color: #ffffff;
  font-size: large;
  font-weight: bold;
}

.footerMenu a {
  color: #ffffff;
  text-decoration: none;
}

.footerMenu a:hover {
  color: #bf0a30;
  text-decoration: underline;
}

.footerLogo {
  display: flex;
  order: 2;
  width: 50%;
  justify-content: center;
  text-align: center;
  align-self: center;
}

.NomNoms-logo-footer {
  height: 20vh;
  background-color: #ffffff;
  box-shadow: 0px 0px 50px #ffffff;
  justify-content: center;
  text-align: center;
  align-self: center;
}

/* //////////////// Responsive Section Burger Menu //////////////// */

.burger-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.burger-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #ffffff;
  margin: 3px 0;
  border-radius: 25px;
}

.burger-links {
  list-style: none;
  display: flex;
}

.arrow-down {
  border: solid #002868;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

@media screen and (max-width: 768px) {
  .burger-icon {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .burger-links.none {
    display: none;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 2s ease, transform 2s ease;
  }

  ul.burger-links.active {
    display: flex;
    flex-direction: column;
    background-color: #002868;
    z-index: 1;
    justify-content: center;
    align-items: center;
    gap: 5px;
    box-shadow: none;
    opacity: 1;
    transform: translateY(0);
  }

  ul.buerMenuu.none {
    display: none;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 2s ease, transform 2s ease;
  }

  .burgerMenuu.active {
    opacity: 1;
    transform: translateY(0);
  }

  .headerMenu-burger {
    padding: 10px;
    display: flex;
    justify-content: center;
    background-color: #002868;
  }

  .headerMenu-burger li {
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: stretch;
    font-weight: bold;
    color: #ffffff;
    font-size: 1.5rem;
  }

  /* .navRestaurants {
    display: none;
  } */
}

/* //////////////// Responsive Section //////////////// */

@media screen and (max-width: 1399px) {
  .restaurant-content {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .restaurant-page-content {
    display: flex;
    width: 90%;
  }

  .recipe-page-content {
    display: flex;
    width: 90%;
  }

  .restaurant-sideBar {
    display: flex;
    width: 90%;
  }

  .recipe-page-container {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .recipe-page-sideBar {
    display: flex;
    width: 90%;
  }

  .home-restaurant-items {
    width: 90%;
  }

  .recipe-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home-recipe-items {
    width: 90%;
  }

  .cuisine-links.none {
    max-height: 0;
    border-width: 0;
    transition: border 5ms ease-in-out, max-height 400ms ease-in-out;
    transition-delay: 400ms, 0s;
  }

  .burger-cuisine {
    border: solid 3px transparent;
    border-color: #002868;
    background-color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    color: #002868;
    border-radius: 10px;
    padding: 5px;
    border-width: 5px;
    width: 200px;
  }

  .cuisine-links {
    overflow: hidden;
    max-height: 500px;
    transition: border 10ms ease-in-out, max-height 400ms ease-in-out;
    /* transition-delay: 3s, 5s; You can mess around with these delays to help smooth out transition*/
    display: flex;
    flex-direction: column;
    /* position: absolute; */
    /* top: 70px;
      right: 10px; */
    background-color: #ffffff;
    border: 5px solid #002868;
    border-radius: 25px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding-inline-start: 0px;
    width: 100%;
  }

  .cuisine-links a {
    list-style-type: none;
    text-decoration: none;
    font-weight: bold;
    color: #002868;
  }

  .cuisine-links a:hover {
    color: #bf0a30;
    text-decoration: underline;
    text-decoration-color: #ffd700;
  }

  .restaurant-content {
    gap: 0px;
  }

  .restaurant-sideBar {
    padding-top: 10px;
  }

  .recipe-page-sideBar {
    padding-top: 10px;
  }

  /* .arrow-down:hover {
    border: solid #bf0a30;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  } */

  .burger-cuisine:hover {
    color: #bf0a30;
    border: 5px solid #bf0a30;
    text-decoration: underline;
    text-decoration-color: #ffd700;
    cursor: pointer;
  }
}

@media screen and (max-width: 1399px) {
  .rest-img {
    width: 40%;
    display: flex;
    justify-content: center;
  }

  .rest-stuff {
    width: 60%;
  }

  .restaurant-image img {
    height: 300px;
    width: 550px;
    border-radius: 10px;
  }

  .rest-review-content {
    padding: 15px;
  }

  .restaurant-food-image img {
    height: 200px;
    width: 350px;
  }

  .recipe-instructions-images img {
    height: 200px;
    width: 350px;
  }

  .rest-review-content {
    width: 90%;
  }

  .recipe-review-content {
    width: 90%;
  }

  .recipe-stuff {
    width: 60%;
  }

  .recipe-page-img {
    width: 40%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 950px) {
  .restaurant-food-image {
    flex-direction: column;
  }

  .restaurant-food-image img {
    height: 300px;
    width: 550px;
  }

  .recipe-main-food-image img {
    height: 300px;
    width: 550px;
  }

  .recipe-ingredients-image img {
    height: 300px;
    width: 550px;
  }
}

@media screen and (max-width: 750px) {
  .aboutPage {
    flex-direction: column;
  }

  .aboutSectionOne {
    width: 90%;
  }

  .NomNoms-logo-footer {
    height: 20vw;
  }

  .restaurant-image img {
    height: 200px;
    width: 350px;
    border-radius: 10px;
  }

  .restaurant-food-image img {
    height: 200px;
    width: 350px;
  }

  .recipe-main-food-image img {
    height: 200px;
    width: 350px;
  }

  .recipe-ingredients-image img {
    height: 200px;
    width: 350px;
  }
}

@media screen and (max-width: 550px) {
  .footer {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }

  .recipe-page-img img {
    height: 150px;
    width: 250px;
  }

  .recipe-main-food-image img {
    height: 150px;
    width: 250px;
  }

  .recipe-ingredients-image img {
    height: 150px;
    width: 250px;
  }

  .restaurant-image img {
    height: 150px;
    width: 250px;
  }

  .NomNoms-logo {
    height: 20vh;
  }
}

@media screen and (max-width: 550px) {
  .rest-img {
    display: none;
  }

  .rest-stuff {
    width: 100%;
  }

  .recipe-page-img {
    display: none;
  }

  .recipe-stuff {
    width: 100%;
  }
}
